
.s-program {
  margin-top: 100px;

  @include sm {
    margin-top: 70px; }
  .section__title {
    margin: 0 auto 56px;

    text-align: center;
    font-size: clamp(40px, 5.8vw, 64px);
    font-weight: 800;
    line-height: .9;

    color: var(--accentColor);

    @include sm {
      margin-bottom: 40px; } } }

.program {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @include sm {
    gap: 12px; }
  &__item {
    flex: 1 1 auto;
    width: calc(33.33% - 15px);
    border-radius: 20px;
    border: 1px solid #353535;
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(10px);
    padding: 44px;

    min-width: 320px;
    @include tablet {
      padding: 44px 40px; }
    @include md {
      padding: 40px 34px;
      min-width: 290px; }
    @include xs {
      padding: 32px 24px; } }
  &__title {
    color: var(--accentColor);
    font-size: 34px;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -0.068em;
    text-transform: uppercase;

    font-family: Sora, sans-serif;
    text-wrap: balance;
    margin-bottom: 36px;
    @include tablet {
      font-size: 30px;
      margin-bottom: 24px; }
    @include md {
      font-size: 28px; }
    @include sm {
      font-size: 24px;
      margin-bottom: 16px; } }
  &__descr {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 34px;
    @include tablet {
      font-size: 14px;
      margin-bottom: 24px; } }

  &__list {
    &-title {
      margin-bottom: 24px;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 600;
      @include tablet {
        margin-bottom: 16px;
        font-size: 14px; } }
    &-icon {
      img {
        display: block; } }
    &-text {
      align-self: center; }
    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include tablet {
        gap: 12px; } }
    li {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      font-size: 14px;
      line-height: 1.5;
      @include tablet {
        gap: 8px; }
      @include md {
        font-size: 12px; } } }

  &__presented {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px 15px;
    margin-top: 34px;
    @include tablet {
      margin-top: 24px; }
    @include sm {
      margin-top: 16px; }
    &-title {
      margin-bottom: 0; }
    img {
      max-width: 160px;
      display: block; } } }
